import React from 'react'
import { graphql } from 'gatsby'

import PageHeader from '../components/PageHeader'
import Content from '../components/Content'
import Layout from '../components/Layout'
import MyContext from '../components/Context'

// Export Template for use in CMS previewssssssss

export const HomePageTemplate = ({ body_en, body_ar, swiper }) => {
  return (
    <MyContext.Consumer>
      {cons => {
        if (!cons) {
          var cons = {
            strings: e => e,
            i18n: {
              language: 'ar'
            }
          }
        }
        return (
          <main className="Home" style={{}}>
            <section>
              <div
                style={{
                  marginBottom: '20px'
                }}
              >
                <PageHeader swiperData={swiper} fromHome={true} />
              </div>
              <h1 style={{ fontSize: '4rem' }}>
                {cons.strings('welcomeToSOMM')}
              </h1>
              <div style={{ padding: '15px', lineHeight: 2, fontSize: '19px' }}>
                <Content
                  source={cons.i18n.language == 'en' ? body_en : body_ar}
                />
              </div>
            </section>
          </main>
        )
      }}
    </MyContext.Consumer>
  )
}

// Export Default HomePage for front-ends
const HomePage = ({ data: { page } }) => {
  return (
    <Layout meta={page.frontmatter.meta || false} fromHome={true}>
      <div style={{}}>
        <HomePageTemplate {...page} {...page.frontmatter} body={page.html} />
      </div>
    </Layout>
  )
}

export default HomePage

export const pageQuery = graphql`
  ## Query for HomePage data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query HomePage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      frontmatter {
        body_ar
        body_en
        title
        subtitle
        featuredImage
        swiper {
          title_ar
          title_en
          sub_title_ar
          sub_title_en
          image
        }
      }
    }
    gate: markdownRemark(id: { eq: "f7018435-4b10-5e81-8672-4387888c7317" }) {
      frontmatter {
        sites_ar {
          site_name
          site_url
          description
          image
          alt
        }
      }
    }
  }
`
